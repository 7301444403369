const countries = [
    {key: "US", display: "United States"},
    {key: "AX", display: "Aland Islands"},
    {key: "AL", display: "Albania"},
    {key: "DZ", display: "Algeria"},
    {key: "AS", display: "American Samoa"},
    {key: "AD", display: "Andorra"},
    {key: "AO", display: "Angola"},
    {key: "AI", display: "Anguilla"},
    {key: "AQ", display: "Antarctica"},
    {key: "AG", display: "Antigua and Barbuda"},
    {key: "AR", display: "Argentina"},
    {key: "AM", display: "Armenia"},
    {key: "AW", display: "Aruba"},
    {key: "AU", display: "Australia"},
    {key: "AT", display: "Austria"},
    {key: "AZ", display: "Azerbaijan"},
    {key: "BS", display: "Bahamas"},
    {key: "BH", display: "Bahrain"},
    {key: "BD", display: "Bangladesh"},
    {key: "BB", display: "Barbados"},
    {key: "BY", display: "Belarus"},
    {key: "BE", display: "Belgium"},
    {key: "BZ", display: "Belize"},
    {key: "BJ", display: "Benin"},
    {key: "BM", display: "Bermuda"},
    {key: "BT", display: "Bhutan"},
    {key: "BO", display: "Bolivia"},
    {key: "BA", display: "Bosnia and Herzegovina"},
    {key: "BW", display: "Botswana"},
    {key: "BV", display: "Bouvet Island"},
    {key: "BR", display: "Brazil"},
    {key: "IO", display: "British Indian Ocean Territory"},
    {key: "BN", display: "Brunei Darussalam"},
    {key: "BG", display: "Bulgaria"},
    {key: "BF", display: "Burkina Faso"},
    {key: "BI", display: "Burundi"},
    {key: "KH", display: "Cambodia"},
    {key: "CM", display: "Cameroon"},
    {key: "CA", display: "Canada"},
    {key: "CV", display: "Cape Verde"},
    {key: "KY", display: "Cayman Islands"},
    {key: "CF", display: "Central African Republic"},
    {key: "TD", display: "Chad"},
    {key: "CL", display: "Chile"},
    {key: "CN", display: "China"},
    {key: "CX", display: "Christmas Island"},
    {key: "CC", display: "Cocos (Keeling) Islands"},
    {key: "CO", display: "Colombia"},
    {key: "KM", display: "Comoros"},
    {key: "CG", display: "Congo"},
    {key: "CD", display: "Congo, The Democratic Republic of The"},
    {key: "CK", display: "Cook Islands"},
    {key: "CR", display: "Costa Rica"},
    {key: "CI", display: "Cote D'ivoire"},
    {key: "HR", display: "Croatia"},
    {key: "CU", display: "Cuba"},
    {key: "CY", display: "Cyprus"},
    {key: "CZ", display: "Czech Republic"},
    {key: "DK", display: "Denmark"},
    {key: "DJ", display: "Djibouti"},
    {key: "DM", display: "Dominica"},
    {key: "DO", display: "Dominican Republic"},
    {key: "EC", display: "Ecuador"},
    {key: "EG", display: "Egypt"},
    {key: "SV", display: "El Salvador"},
    {key: "GQ", display: "Equatorial Guinea"},
    {key: "ER", display: "Eritrea"},
    {key: "EE", display: "Estonia"},
    {key: "ET", display: "Ethiopia"},
    {key: "FK", display: "Falkland Islands (Malvinas)"},
    {key: "FO", display: "Faroe Islands"},
    {key: "FJ", display: "Fiji"},
    {key: "FI", display: "Finland"},
    {key: "FR", display: "France"},
    {key: "GF", display: "French Guiana"},
    {key: "PF", display: "French Polynesia"},
    {key: "TF", display: "French Southern Territories"},
    {key: "GA", display: "Gabon"},
    {key: "GM", display: "Gambia"},
    {key: "GE", display: "Georgia"},
    {key: "DE", display: "Germany"},
    {key: "GH", display: "Ghana"},
    {key: "GI", display: "Gibraltar"},
    {key: "GR", display: "Greece"},
    {key: "GL", display: "Greenland"},
    {key: "GD", display: "Grenada"},
    {key: "GP", display: "Guadeloupe"},
    {key: "GU", display: "Guam"},
    {key: "GT", display: "Guatemala"},
    {key: "GG", display: "Guernsey"},
    {key: "GN", display: "Guinea"},
    {key: "GW", display: "Guinea-bissau"},
    {key: "GY", display: "Guyana"},
    {key: "HT", display: "Haiti"},
    {key: "HM", display: "Heard Island and Mcdonald Islands"},
    {key: "VA", display: "Holy See (Vatican City State)"},
    {key: "HN", display: "Honduras"},
    {key: "HK", display: "Hong Kong"},
    {key: "HU", display: "Hungary"},
    {key: "IS", display: "Iceland"},
    {key: "IN", display: "India"},
    {key: "ID", display: "Indonesia"},
    {key: "IR", display: "Iran, Islamic Republic of"},
    {key: "IQ", display: "Iraq"},
    {key: "IE", display: "Ireland"},
    {key: "IM", display: "Isle of Man"},
    {key: "IL", display: "Israel"},
    {key: "IT", display: "Italy"},
    {key: "JM", display: "Jamaica"},
    {key: "JP", display: "Japan"},
    {key: "JE", display: "Jersey"},
    {key: "JO", display: "Jordan"},
    {key: "KZ", display: "Kazakhstan"},
    {key: "KE", display: "Kenya"},
    {key: "KI", display: "Kiribati"},
    {key: "KP", display: "Korea, Democratic People's Republic of"},
    {key: "KR", display: "Korea, Republic of"},
    {key: "KW", display: "Kuwait"},
    {key: "KG", display: "Kyrgyzstan"},
    {key: "LA", display: "Lao People's Democratic Republic"},
    {key: "LV", display: "Latvia"},
    {key: "LB", display: "Lebanon"},
    {key: "LS", display: "Lesotho"},
    {key: "LR", display: "Liberia"},
    {key: "LY", display: "Libyan Arab Jamahiriya"},
    {key: "LI", display: "Liechtenstein"},
    {key: "LT", display: "Lithuania"},
    {key: "LU", display: "Luxembourg"},
    {key: "MO", display: "Macao"},
    {key: "MK", display: "Macedonia, The Former Yugoslav Republic of"},
    {key: "MG", display: "Madagascar"},
    {key: "MW", display: "Malawi"},
    {key: "MY", display: "Malaysia"},
    {key: "MV", display: "Maldives"},
    {key: "ML", display: "Mali"},
    {key: "MT", display: "Malta"},
    {key: "MH", display: "Marshall Islands"},
    {key: "MQ", display: "Martinique"},
    {key: "MR", display: "Mauritania"},
    {key: "MU", display: "Mauritius"},
    {key: "YT", display: "Mayotte"},
    {key: "MX", display: "Mexico"},
    {key: "FM", display: "Micronesia, Federated States of"},
    {key: "MD", display: "Moldova, Republic of"},
    {key: "MC", display: "Monaco"},
    {key: "MN", display: "Mongolia"},
    {key: "ME", display: "Montenegro"},
    {key: "MS", display: "Montserrat"},
    {key: "MA", display: "Morocco"},
    {key: "MZ", display: "Mozambique"},
    {key: "MM", display: "Myanmar"},
    {key: "NA", display: "Namibia"},
    {key: "NR", display: "Nauru"},
    {key: "NP", display: "Nepal"},
    {key: "NL", display: "Netherlands"},
    {key: "AN", display: "Netherlands Antilles"},
    {key: "NC", display: "New Caledonia"},
    {key: "NZ", display: "New Zealand"},
    {key: "NI", display: "Nicaragua"},
    {key: "NE", display: "Niger"},
    {key: "NG", display: "Nigeria"},
    {key: "NU", display: "Niue"},
    {key: "NF", display: "Norfolk Island"},
    {key: "MP", display: "Northern Mariana Islands"},
    {key: "NO", display: "Norway"},
    {key: "OM", display: "Oman"},
    {key: "PK", display: "Pakistan"},
    {key: "PW", display: "Palau"},
    {key: "PS", display: "Palestinian Territory, Occupied"},
    {key: "PA", display: "Panama"},
    {key: "PG", display: "Papua New Guinea"},
    {key: "PY", display: "Paraguay"},
    {key: "PE", display: "Peru"},
    {key: "PH", display: "Philippines"},
    {key: "PN", display: "Pitcairn"},
    {key: "PL", display: "Poland"},
    {key: "PT", display: "Portugal"},
    {key: "PR", display: "Puerto Rico"},
    {key: "QA", display: "Qatar"},
    {key: "RE", display: "Reunion"},
    {key: "RO", display: "Romania"},
    {key: "RU", display: "Russian Federation"},
    {key: "RW", display: "Rwanda"},
    {key: "SH", display: "Saint Helena"},
    {key: "KN", display: "Saint Kitts and Nevis"},
    {key: "LC", display: "Saint Lucia"},
    {key: "PM", display: "Saint Pierre and Miquelon"},
    {key: "VC", display: "Saint Vincent and The Grenadines"},
    {key: "WS", display: "Samoa"},
    {key: "SM", display: "San Marino"},
    {key: "ST", display: "Sao Tome and Principe"},
    {key: "SA", display: "Saudi Arabia"},
    {key: "SN", display: "Senegal"},
    {key: "RS", display: "Serbia"},
    {key: "SC", display: "Seychelles"},
    {key: "SL", display: "Sierra Leone"},
    {key: "SG", display: "Singapore"},
    {key: "SK", display: "Slovakia"},
    {key: "SI", display: "Slovenia"},
    {key: "SB", display: "Solomon Islands"},
    {key: "SO", display: "Somalia"},
    {key: "ZA", display: "South Africa"},
    {key: "GS", display: "South Georgia and The South Sandwich Islands"},
    {key: "ES", display: "Spain"},
    {key: "LK", display: "Sri Lanka"},
    {key: "SD", display: "Sudan"},
    {key: "SR", display: "Suriname"},
    {key: "SJ", display: "Svalbard and Jan Mayen"},
    {key: "SZ", display: "Swaziland"},
    {key: "SE", display: "Sweden"},
    {key: "CH", display: "Switzerland"},
    {key: "SY", display: "Syrian Arab Republic"},
    {key: "TW", display: "Taiwan, Province of China"},
    {key: "TJ", display: "Tajikistan"},
    {key: "TZ", display: "Tanzania, United Republic of"},
    {key: "TH", display: "Thailand"},
    {key: "TL", display: "Timor-leste"},
    {key: "TG", display: "Togo"},
    {key: "TK", display: "Tokelau"},
    {key: "TO", display: "Tonga"},
    {key: "TT", display: "Trinidad and Tobago"},
    {key: "TN", display: "Tunisia"},
    {key: "TR", display: "Turkey"},
    {key: "TM", display: "Turkmenistan"},
    {key: "TC", display: "Turks and Caicos Islands"},
    {key: "TV", display: "Tuvalu"},
    {key: "UG", display: "Uganda"},
    {key: "UA", display: "Ukraine"},
    {key: "AE", display: "United Arab Emirates"},
    {key: "GB", display: "United Kingdom"},
    {key: "UM", display: "United States Minor Outlying Islands"},
    {key: "UY", display: "Uruguay"},
    {key: "UZ", display: "Uzbekistan"},
    {key: "VU", display: "Vanuatu"},
    {key: "VE", display: "Venezuela"},
    {key: "VN", display: "Vietnam"},
    {key: "VG", display: "Virgin Islands, British"},
    {key: "VI", display: "Virgin Islands, U.S."},
    {key: "WF", display: "Wallis and Futuna"},
    {key: "EH", display: "Western Sahara"},
    {key: "YE", display: "Yemen"},
    {key: "ZM", display: "Zambia"},
    {key: "ZW", display: "Zimbabwe"}
];

export default countries